import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {addPosition, logoutTeacher, saveSchoolSettings, sosPos,} from "../../actions/authActions";
import PickDate from "../func-components/pickDate";
import PickEndDate from "../func-components/pickEndDate";
import CustomNavbar from "../navigation/customNavbar";
import Subjects from "../../typeaheadData/subjects";
import Subjects_fi from "../../typeaheadData/subjects_fi";
import GradeArray from "../../typeaheadData/gradeArray";
import {Typeahead} from "react-bootstrap-typeahead";
import TeacherMobileLayout from "../mobile/teacherMobileLayout";
import {default as Vikariat} from "../navigation/icons/add_vikariat.svg";
import {default as RemoveIcon} from "./icons/delete_purple.svg";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import {hours, minutes} from "./data/times";
import Sort from "../func-components/sort";
import QualificationList from "../../typeaheadData/qualifications";
import ChangeSchoolModal from "../func-components/changeSchoolModal";

//bootstrap && css imports
import {Button, Card, Col, Container, Form, ListGroup, ListGroupItem, Modal, Row,} from "react-bootstrap";
import "../../styles/body-verticalCenter.css";
import "../../styles/addTempPos.css";
import axios from "axios";
import ChooseSchool from "../buttons/ChooseSchool";

class AddTempPos extends Component {
    constructor() {
        super();
        this.state = {
            owner: "",
            school: "",
            startDate: new Date(),
            endDate: null,
            startTime: "08:00",
            endTime: "15:30",
            reason: "",
            subjects: [],
            type: "",
            extraSubjects: [],
            additionalInfo: "",
            errors: {},
            files: [],
            refs: "",
            focusedInput: null,
            sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
            multipleDays: false,
            uploadProgress: null,
            success: null,
            uploadedFiles: null,
            mobile: false,
            language: {},
            open: false,
            prin: {},
            editinfo: false,
            start_sos: "",
            end_sos: "",
            info_sos: "",
            subjects_sos: [],
            teacher: [],
            teachers: [],
            gradeTeach: null,
            otherTxt: "",
            other: false,
            changeSchool: false,
            tempTypeNull: false,
            fromAdminType: "Tips",
            noSchool: false,
            isLoading: false
        };
        this.inputFileRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.auth.teacher.typeOfUser === "user") {
            this.props.history.push("/");
        }

        this.getLang(this.props.auth.teacher);

        const handler = () => {
            let match = window.matchMedia("(max-width: 600px)").matches;
            if (match) {
                this.setState({mobile: true});
            } else {
                this.setState({mobile: false});
            }
        };
        handler();
        window.addEventListener("resize", handler);

        const data = {
            email: this.props.auth.teacher.email,
        };

        if (this.props.auth.teacher.typeOfUser === "principal") {
            this.getTeachers(this.props.auth.teacher.email);
            axios
                .post("/api/principals/get/principal", data)
                .then((res) => {
                    this.setState({prin: res.data, gradeTeach: res.data.grade}, () => {
                        if (this.state.prin.defaults) {
                            const d = this.state.prin.defaults;
                            this.setState({
                                start_sos: d.start,
                                end_sos: d.end,
                                info_sos: d.info,
                                subjects_sos: d.subjects,
                            });
                        }
                    });
                })
                .catch((err) => console.log(err));
        }
        if (this.props.auth.teacher.typeOfUser === "teacher") {
            axios
                .post("/api/teachers/get/teacher", data)
                .then((res) => {
                    this.setState({gradeTeach: res.data.grade});
                })
                .catch((err) => console.log(err));
        }

        axios
            .post("/api/shared/getschool", data)
            .then((res) => {
                let bool =
                    this.props.auth.teacher.typeOfUser === "principal" &&
                    (this.state.prin?.moreSchools || []).length > 0 &&
                    typeof this.state.prin.moreSchools !== "undefined";
                this.setState({school: bool ? null : res.data.school});
            })
            .catch((err) => console.log(err));
    }

    getTeachers = (email) => {
        let data = {
            email: email,
        };

        axios
            .post("/api/principals/teachers/getall", data)
            .then((res) => {
                this.setState({teachers: res.data.teachers});
            })
            .catch((err) => console.log(err));
    };

    getLang = (user) => {
        if (user.siteLang === 2) {
            this.setState({language: sv});
        } else if (user.siteLang === 1) {
            this.setState({language: fi});
        } else {
            this.setState({language: fi});
        }
    };

    /* NOTE: removed this to stop page scrolling up whenever you started typing on some screensizes. Think its unnecessary now since basically no fields are required 17-03-2022 */
    /* static getDerivedStateFromProps(nextProps, state) {
                                                              if (nextProps.errors) {
                                                                  window.scrollTo(0, 0)
                                                                  return {
                                                                      errors: nextProps.errors
                                                                  }
                                                              }
                                                          } */

    onChange = (e) => {
        if (e.target.id == "type" && e.target.value === "eleven") {
            this.setState({other: true});
        } else if (e.target.id === "type" && e.target.value !== "eleven") {
            this.setState({other: false});
        }
        this.setState({[e.target.id]: e.target.value});
    };

    onStartDateChange = (date) => {
        this.setState({
            startDate: date,
        });
    };
    onEndDateChange = (date) => {
        this.setState({
            endDate: date,
        });
    };

    changeSchoolFunc = (name) => {
        this.setState({school: name, changeSchool: false});
    };

    handleFileClick = (e) => {
        this.inputFileRef.current.click();
    };

    onFileChange = (e) => {
        const files = e.target.files;
        let temp = this.state.files || [];
        for (let i = 0; i < files.length; i++) {
            temp.push(files[i]);
        }
        this.setState({files: temp});
    };

    handleRemoveFile = (e) => {
        e.preventDefault();

        if (this.state.files.length === 1) {
            this.setState({files: []});
        } else {
            const index = e.target.id;
            let temp = this.state.files;
            temp.splice(index, 1);
            this.setState({files: temp});
        }
    };

    handleFileUpload = () => {
        return new Promise((resolve, reject) => {
            const formData = new FormData();
            this.state.files.forEach((file) => {
                formData.append("files", file, file.name);
            });
            axios
                .post("/api/shared/upload/files", formData, {
                    onUploadProgress: (data) => {
                        this.setState({
                            uploadProgress: Math.round((100 * data.loaded) / data.total),
                        });
                    },
                })
                .then((res) => {
                    if (res.data.success) {
                        resolve(true);
                    } else {
                        reject("Promise rejected, success false");
                    }
                    this.setState({
                        uploadProgress: null,
                        success: res.data.success,
                        uploadedFiles: res.data.files,
                    });
                })
                .catch((err) => console.error(err));
        });
    };

    onSubmit = (e) => {
        this.setState({isLoading: true});
        e.preventDefault();
        this.handleFileUpload()
            .then((res) => {
                let fileNameArr = [];
                let expire;
                if (this.state.uploadedFiles !== null) {
                    this.state.uploadedFiles.forEach((file) => {
                        fileNameArr.push(file.filename);
                    });
                }

                let regardingTxt =
                    this.state?.teacher[0]?.firstName +
                    " " +
                    this.state?.teacher[0]?.lastName;
                if (regardingTxt === "undefined undefined") {
                    regardingTxt = "";
                }
                const splitTime = this.state.endTime.split(":");
                if (this.state.endDate) {
                    let newDate = new Date(this.state.endDate);
                    const withTime = newDate.setHours(splitTime[0], splitTime[1]);
                    expire = new Date(withTime);
                } else {
                    let newDate = new Date(this.state.startDate);
                    const withTime = newDate.setHours(splitTime[0], splitTime[1]);
                    expire = new Date(withTime);
                }

                /* let combineSubjects = [...this.state.subjects, ...this.state.extraSubjects.map(item => item.label)]
                                                                                                                                                                                                                                        this.setState({ subjects: combineSubjects }) */
                let subjectsLabelsOnly = [];
                this.state.subjects.map((subject, index) =>
                    subject.label
                        ? subjectsLabelsOnly.push(subject.label)
                        : subjectsLabelsOnly.push(subject)
                );
                /* console.log("SLO: ", subjectsLabelsOnly) */
                let tempType =
                    this.state.type === "eleven"
                        ? {
                            val: "eleven",
                            name_swe: this.state.otherTxt,
                            name_fi: this.state.otherTxt,
                        }
                        : QualificationList.find((e) => e.val === this.state.type);
                if (typeof tempType === "undefined") {
                    this.setState({tempTypeNull: true, isLoading: false});
                } else if (
                    this.props.auth.teacher.typeOfUser === "principal" &&
                    (this.state.prin?.moreSchools || []).length > 0 &&
                    this.state.school === null
                ) {
                    this.setState({noSchool: true, isLoading: false}, () => window.scrollTo(0, 0));
                } else {
                    const newPos = {
                        owner: this.props.auth.teacher._id,
                        school: this.state.school,
                        startDate: this.state.startDate.toDateString(),
                        endDate: this.state.endDate
                            ? this.state.endDate.toDateString()
                            : this.state.startDate.toDateString(),
                        startTime: this.state.startTime || "08:00",
                        endTime: this.state.endTime || "15:30",
                        reason: this.state.reason,
                        subjects: subjectsLabelsOnly,
                        additionalInfo: this.state.additionalInfo,
                        files: fileNameArr,
                        regarding: regardingTxt ? regardingTxt : "",
                        grades: this.state.gradeTeach,
                        expire,
                        typeOfTemp: tempType,
                        otherTxt: this.state.otherTxt,
                        fromAdminType: this.state.fromAdminType || null,
                    };
                    //console.log(newPos);
                    this.props.addPosition(newPos, this.props.history);
                    //this.setState({isLoading: false})
                }
            })
            .catch((err) => {
                console.log(err)
                this.setState({isLoading: false})
            });
    };

    handleCheckbox = (e) => {
        const {multipleDays} = this.state;
        let check = e.target.checked;
        if (check) {
            this.setState({multipleDays: true});
        } else {
            this.setState({multipleDays: false});
        }
    };

    buttonOrNo = () => {
        if (this.props.auth.teacher.typeOfUser !== "principal") {
            return "";
        } else if (this.props.auth.teacher.typeOfUser === "principal") {
            return (
                <Button variant="custom" block size="lg" onClick={this.onSOSClick}>
                    SOS Vikariat
                </Button>
            );
        } else {
            return "";
        }
    };

    onSOSClick = () => {
        const data = {
            email: this.props.auth.teacher.email,
        };

        this.setState({open: true});

        /* if (window.confirm('Är du säker du vill lägga ut ett SOS vikariat?')) {
                                                                                                                        this.props.sosPos(data, this.props.history);
                                                                                                                        alert("Du har laggt ut ett SOS vikariat")
                                                                                                                    } else {
                                                                                                                        alert("Du valde att inte lägga ut ett SOS vikariat")
                                                                                                                    } */
    };

    postSos = async () => {
        this.setState({isLoading: true})
        const d = this.state.prin.defaults;
        const data = {
            email: this.props.auth.teacher.email,
            start: d.start,
            end: d.end,
            info: d.info,
            school: this.state.school ?? null,
        };
        await this.props.sosPos(data);
        this.props.history.push("/jobs/overview");
        this.setState({isLoading: false})
    };

    editSos = () => {
        this.setState({editinfo: true});
    };

    callSaveSettings = (_callback) => {
        const s = this.state;
        const data = {
            email: this.props.auth.teacher.email,
            subjects: s.subjects_sos,
            start: s.start_sos,
            end: s.end_sos,
            info: s.info_sos,
        };
        let saveSettingsPromise = this.props.saveSchoolSettings(data);
        saveSettingsPromise.then(() => _callback());
    };
    updatePrin = () => {
        this.callSaveSettings(() => {
            const data = {
                email: this.props.auth.teacher.email,
            };
            axios
                .post("/api/principals/get/principal", data)
                .then((res) => {
                    this.setState({prin: res.data}, () => {
                        if (this.state.prin.defaults) {
                            const d = this.state.prin.defaults;
                            this.setState(
                                {
                                    start_sos: d.start,
                                    end_sos: d.end,
                                    info_sos: d.info,
                                    subjects_sos: d.subjects,
                                },
                                () => {
                                    this.setState({editinfo: false});
                                }
                            );
                        }
                    });
                })
                .catch((err) => console.log(err));
        });
    };

    saveEdit = (e) => {
        e.preventDefault();

        this.updatePrin();
    };

    handleCloseChange = () => {
        this.setState({open: false, editinfo: false});
    };

    handleSchoolCloseChange = () => {
        this.setState({changeSchool: false});
    };

    showSidebar = () => {
        const {sidebar} = this.state;
        if (sidebar) {
            this.setState({sidebar: false});
        } else if (!sidebar) {
            this.setState({sidebar: true});
        }
    };

    onLogoutClick = () => {
        this.props.logoutTeacher();
    };

    render() {
        /* const { errors } = this.state */
        /* const subjects = ['Matematik', 'Svenska', 'Biologi', 'Gymnastik', 'Musik', 'Historia', 'Fysik', 'Geografi', 'Huslig Ekonomi']
                                                                                                                    const subjectsRight = ['Religion', 'Träslöjd', 'Finska', 'Syslöjd', 'Främmande språk', 'Samhällslära', 'Kemi', 'Bildkonst'] */
        const {
            sidebar,
            subjects,
            multipleDays,
            mobile,
            language,
            other,
            open,
            prin,
            editinfo,
            info_sos,
            start_sos,
            otherTxt,
            end_sos,
            subjects_sos,
            errors,
            gradeTeach,
            extraSubjects,
            changeSchool,
        } = this.state;
        const {teacher} = this.props.auth;
        const imgUrl = `${window.location.origin}/api/shared/image/`;

        let button;
        if (this.props.auth.teacher.typeOfUser !== "principal") {
            button = "";
        } else if (this.props.auth.teacher.typeOfUser === "principal") {
            button = (
                <Button
                    variant="sos"
                    block
                    style={{marginTop: "10px"}}
                    onClick={this.onSOSClick}
                >
                    {language.sos}
                </Button>
            );
        } else {
            button = "";
        }

        // {this.state.isLoading && <Loading/>}
        return (<>
            <div className="temp-body">

                <CustomNavbar
                    userType={teacher.typeOfUser}
                    sidebar={sidebar}
                    showSidebar={this.showSidebar}
                    page={language.nav_tempjobs}
                    user={teacher}
                    logoutTeacher={this.onLogoutClick}
                    isAuth={this.props.auth.isAuthenticated}
                />
                {!mobile && (
                    <>
                        <Sort
                            header={language.newjob}
                            userType={teacher.typeOfUser}
                            marginLeft={sidebar ? "215px" : "16px"}
                            school={teacher.school}
                        />
                        <hr className="custom-hr mb-3"></hr>
                    </>
                )}
                <div className={sidebar ? "main push-right" : "main"}>
                    <Container
                        fluid
                        style={{
                            minHeight: "100%",
                            paddingLeft: mobile ? "0" : "20px",
                            paddingRight: mobile ? "0" : "10px",
                        }}
                    >
                        {changeSchool && (
                            <ChangeSchoolModal
                                isConfirmOpen={changeSchool}
                                handleCloseConfirmChange={this.handleSchoolCloseChange}
                                prin={prin}
                                changeSchool={this.changeSchoolFunc}
                                language={language}
                            />
                        )}
                        {!mobile ? (
                            <Card
                                style={{
                                    borderRadius: "10px",
                                    border: "none",
                                    boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                    padding: "20px",
                                    marginTop: "20px",
                                }}
                            >
                                <Form noValidate onSubmit={this.onSubmit}>
                                    <Row className="f-col">
                                        <Col>
                                            <Card.Body style={{fontSize: "1.2em"}}>
                                                <Card.Title>
                                                    <h4>
                                                        {this.state.school}
                                                        {prin?.moreSchools?.length > 0 && (
                                                            <ChooseSchool
                                                                language={language}
                                                                onClick={(e) =>
                                                                    this.setState({school: e.currentTarget.id})
                                                                }
                                                                options={[prin.school, ...prin?.moreSchools]}
                                                                reminder={
                                                                    !this.state.school &&
                                                                    this.state.noSchool && (
                                                                        <span className="check-school m-auto">
                                      <p>!</p>
                                    </span>
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                        {prin.school === "Admin" && (
                                                            <Form.Group controlId="fromAdminType">
                                                                <Form.Label className="text-sm">
                                                                    Type of admin message
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={this.onChange}
                                                                    value={this.state.fromAdminType}
                                                                >
                                                                    <option value="Tips">
                                                                        Vikaaria tiedottaa/informerar
                                                                    </option>
                                                                    <option disabled value="Update">
                                                                        Update
                                                                    </option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        )}
                                                    </h4>
                                                </Card.Title>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="date">
                                                        <Form.Label className="lt cBold">
                                                            {language.date}{" "}
                                                        </Form.Label>
                                                        <span className="text-danger lt">
                              &nbsp; <small>{errors.startDate}</small>
                              <small>{errors.endDate}</small>
                            </span>
                                                        <PickDate
                                                            language={language}
                                                            startDate={this.state.startDate}
                                                            handleStartDateChange={this.onStartDateChange}
                                                        />
                                                    </Form.Group>
                                                    <span style={{marginTop: "2.5rem"}}> - </span>
                                                    <Form.Group as={Col} controlId="enddate">
                                                        <Form.Label className="lt cBold">&nbsp;</Form.Label>
                                                        <PickEndDate
                                                            language={language}
                                                            startDate={this.state.startDate}
                                                            endDate={this.state.endDate}
                                                            handleEndDateChange={this.onEndDateChange}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="startTime">
                                                        <Form.Label className="lt cBold">
                                                            {language.begins}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.onChange}
                                                            value={this.state.startTime}
                                                        >
                                                            {hours.map((hour, hr_index) =>
                                                                minutes.map((min, index) => (
                                                                    <option key={index}>
                                                                        {hour + ":" + min}
                                                                    </option>
                                                                ))
                                                            )}
                                                        </Form.Control>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="endTime">
                                                        <Form.Label className="lt cBold">
                                                            {language.ends}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={this.onChange}
                                                            value={this.state.endTime}
                                                        >
                                                            {hours.map((hour, hr_index) =>
                                                                minutes.map((min, index) => (
                                                                    <option
                                                                        disabled={
                                                                            this.state.startTime >= hour + ":" + min
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        key={index}
                                                                    >
                                                                        {hour + ":" + min}
                                                                    </option>
                                                                ))
                                                            )}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                {this.props.auth.teacher.typeOfUser === "principal" && (
                                                    <>
                                                        <Form.Row>
                                                            <Form.Group as={Col} controlId="teacher">
                                                                <Form.Label className="lt cBold">
                                                                    {language.teacher_regarding}{" "}
                                                                    <small className="text-muted">
                                                                        {language.onlyforprin}
                                                                    </small>
                                                                </Form.Label>
                                                                <Typeahead
                                                                    clearButton
                                                                    className="subject-picker"
                                                                    id="teacher-picker"
                                                                    labelKey={(option) =>
                                                                        `${option.firstName} ${option.lastName}`
                                                                    }
                                                                    options={this.state.teachers}
                                                                    placeholder={language.oneornone}
                                                                    onChange={(teacher) => {
                                                                        if (!teacher.length) {
                                                                            // Clear button was clicked, do some stuff...
                                                                            this.setState({
                                                                                teacher,
                                                                                gradeTeach: prin.grade,
                                                                            });
                                                                        } else {
                                                                            this.setState({
                                                                                teacher,
                                                                                gradeTeach:
                                                                                    teacher[0]?.grade || prin.grade,
                                                                            });
                                                                        }
                                                                    }}
                                                                    selected={this.state.teacher}
                                                                />
                                                            </Form.Group>
                                                        </Form.Row>
                                                    </>
                                                )}
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="reason">
                                                        <Form.Label className="lt cBold">
                                                            {language.reason}{" "}
                                                            <small className="text-muted">
                                                                {language.onlyforprin}
                                                            </small>
                                                        </Form.Label>
                                                        <span className="text-danger lt">
                              &nbsp;
                                                            <small>
                                {language.fi
                                    ? errors?.reason?.fi
                                    : errors?.reason?.sv}
                              </small>
                            </span>
                                                        <Form.Control
                                                            type="text"
                                                            onChange={this.onChange}
                                                            value={this.state.reason}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="type">
                                                        <Form.Label className="lt cBold">
                                                            {language.jobfor}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="select"
                                                            onChange={(event) => {
                                                                this.onChange(event);
                                                                this.setState({tempTypeNull: false});
                                                            }}
                                                            value={this.state.type}
                                                            style={{
                                                                borderColor:
                                                                    this.state.tempTypeNull && "#ff6175",
                                                            }}
                                                        >
                                                            <option value="" selected disabled hidden>
                                                                {language.fi ? "Valitse" : "Välj"}
                                                            </option>
                                                            {QualificationList.map((quali, index) => (
                                                                <option value={quali.val}>
                                                                    {language.fi ? quali.name_fi : quali.name_swe}
                                                                </option>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Form.Row>
                                                {this.state.other && (
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="otherTxt">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={
                                                                    language.fi ? "Muu, mikä?" : "Annan, vad?"
                                                                }
                                                                onChange={this.onChange}
                                                                value={this.state.otherTxt}
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                )}
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="lt cBold">
                                                            {language.chosensubjects}
                                                        </Form.Label>
                                                        <span className="text-danger lt">
                              &nbsp;
                                                            <small>
                                {language.fi
                                    ? errors?.subjects?.fi
                                    : errors?.subjects?.sv}
                              </small>
                            </span>
                                                        <Typeahead
                                                            labelKey="label"
                                                            className="subject-picker"
                                                            id="subject-picker"
                                                            multiple
                                                            allowNew
                                                            newSelectionPrefix={
                                                                language.sv ? "Lägg till: " : "Lisää: "
                                                            }
                                                            options={
                                                                language.sv
                                                                    ? Subjects.sort()
                                                                    : Subjects_fi.sort()
                                                            }
                                                            placeholder={language.oneormore}
                                                            onChange={(subjects) => {
                                                                this.setState({subjects});
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                {/* <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="lt cBold">{language.extrasubjects}</Form.Label>
                                                        <Typeahead
                                                            allowNew
                                                            id="extra-subjects"
                                                            className="subject-picker"
                                                            multiple
                                                            options={[]}
                                                            newSelectionPrefix={language.addextrasubject}
                                                            onChange={(extraSubjects) => {
                                                                this.setState({ extraSubjects })
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Form.Row> */}
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="lt cBold">
                                                            {language.grade}
                                                        </Form.Label>
                                                        <Typeahead
                                                            className="subject-picker"
                                                            placeholder={language.oneormore_standard}
                                                            id="grade-picker"
                                                            selected={gradeTeach}
                                                            multiple
                                                            options={
                                                                language.fi ? GradeArray.fi : GradeArray.sv
                                                            }
                                                            onChange={(gradeTeach) => {
                                                                this.setState({
                                                                    gradeTeach: gradeTeach.sort(),
                                                                });
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Group controlId="additionalInfo">
                                                    <Form.Label className="lt cBold">
                                                        {language.extra}
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        rows={4}
                                                        placeholder={language.additionalInfoText}
                                                        onChange={this.onChange}
                                                        value={this.state.additionalInfo}
                                                    />
                                                </Form.Group>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.Label className="lt cBold">
                                                            {language.sostandardtext}
                                                        </Form.Label>
                                                        <div
                                                            className="text-muted"
                                                            style={{fontSize: "1.4em"}}
                                                        >
                                                            {teacher?.defaultDescription}
                                                        </div>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col}>
                                                        <Form.File
                                                            ref={this.inputFileRef}
                                                            className="text-muted"
                                                            id="files"
                                                            type="file"
                                                            onChange={this.onFileChange}
                                                            style={{display: "none"}}
                                                            multiple
                                                        />
                                                        <Button
                                                            variant="custom-reverse"
                                                            block
                                                            onClick={this.handleFileClick}
                                                        >
                                                            {language.choosedocs}
                                                        </Button>
                                                        <p
                                                            className="text-muted mt-2 text-center"
                                                            style={{fontSize: "1em"}}
                                                        >
                                                            {language.docswarning}
                                                        </p>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Row>
                                                    <ListGroup className="mb-3" as={Col}>
                                                        {this.state.files.length > 0
                                                            ? this.state.files.map((file, index) => (
                                                                <ListGroupItem key={index}>
                                                                    <img
                                                                        id={index}
                                                                        onClick={this.handleRemoveFile}
                                                                        className="mr-2 pe-cursor"
                                                                        src={RemoveIcon}
                                                                        alt=""
                                                                    />{" "}
                                                                    {file.name}
                                                                    <p
                                                                        style={{
                                                                            display:
                                                                                file.size / 1024 / 1024 > 1
                                                                                    ? "initial"
                                                                                    : "none",
                                                                        }}
                                                                        className="text-danger lt"
                                                                    >
                                                                        {" "}
                                                                        *Filen är för stor(max 1mb)
                                                                    </p>
                                                                </ListGroupItem>
                                                            ))
                                                            : ""}
                                                    </ListGroup>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Button
                                                            variant="custom"
                                                            block
                                                            size="lg"
                                                            type="submit"
                                                            disabled={this.state.isLoading}
                                                        >
                                                            {language.addjob}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>{button}</Col>
                                                </Row>
                                            </Card.Body>
                                        </Col>
                                    </Row>
                                </Form>
                            </Card>
                        ) : (
                            <>
                                <TeacherMobileLayout
                                    icon={Vikariat}
                                    text={language.addjob}
                                    user={teacher}
                                    renderCard={false}
                                />
                                <div
                                    style={{marginBottom: "1rem"}}
                                    className="m-cards" /* style={{ marginLeft: "20px", marginRight: "20px" }} */
                                >
                                    <Col md={12} className="mb-3 mt-3">
                                        <Card
                                            className=""
                                            style={{
                                                borderRadius: "10px",
                                                border: "none",
                                                boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                                                padding: "",
                                            }}
                                        >
                                            <Form noValidate onSubmit={this.onSubmit}>
                                                <Row>
                                                    <Col>
                                                        <Card.Body style={{fontSize: "1.4em"}}>
                                                            <Card.Title>
                                                                <h4>
                                                                    {this.state.noSchool && (
                                                                        <span className="check-school">
                                      <p>!</p>
                                    </span>
                                                                    )}
                                                                    {this.state.school}{" "}
                                                                    {prin?.moreSchools?.length > 0 && (
                                                                        <ChooseSchool
                                                                            language={language}
                                                                            onClick={(e) =>
                                                                                this.setState({
                                                                                    school: e.currentTarget.id,
                                                                                })
                                                                            }
                                                                            options={[
                                                                                prin.school,
                                                                                ...prin?.moreSchools,
                                                                            ]}
                                                                            reminder={
                                                                                !this.state.school &&
                                                                                this.state.noSchool && (
                                                                                    <span className="check-school m-auto">
                                            <p>!</p>
                                          </span>
                                                                                )
                                                                            }
                                                                        />
                                                                    )}
                                                                </h4>
                                                            </Card.Title>
                                                            <Form.Row>
                                                                <Form.Group as={Col} controlId="date">
                                                                    <Form.Label>{language.date}</Form.Label>
                                                                    <PickDate
                                                                        language={language}
                                                                        startDate={this.state.startDate}
                                                                        handleStartDateChange={
                                                                            this.onStartDateChange
                                                                        }
                                                                    />
                                                                </Form.Group>
                                                                <span style={{marginTop: "2rem"}}> - </span>
                                                                <Form.Group as={Col} controlId="enddate">
                                                                    <Form.Label>&nbsp;</Form.Label>
                                                                    <PickEndDate
                                                                        language={language}
                                                                        startDate={this.state.startDate}
                                                                        endDate={this.state.endDate}
                                                                        handleEndDateChange={this.onEndDateChange}
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} controlId="startTime">
                                                                    <Form.Label>{language.begins}</Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={this.onChange}
                                                                        value={this.state.startTime}
                                                                    >
                                                                        {hours.map((hour, hr_index) =>
                                                                            minutes.map((min, index) => (
                                                                                <option key={index}>
                                                                                    {hour + ":" + min}
                                                                                </option>
                                                                            ))
                                                                        )}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                                <Form.Group as={Col} controlId="endTime">
                                                                    <Form.Label>{language.ends}</Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={this.onChange}
                                                                        value={this.state.endTime}
                                                                    >
                                                                        {hours.map((hour, hr_index) =>
                                                                            minutes.map((min, index) => (
                                                                                <option
                                                                                    disabled={
                                                                                        this.state.startTime >=
                                                                                        hour + ":" + min
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    {hour + ":" + min}
                                                                                </option>
                                                                            ))
                                                                        )}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            {this.props.auth.teacher.typeOfUser ===
                                                                "principal" && (
                                                                    <>
                                                                        <Form.Row>
                                                                            <Form.Group as={Col} controlId="teacher">
                                                                                <Form.Label>
                                                                                    {language.teacher_regarding}
                                                                                </Form.Label>
                                                                                <Typeahead
                                                                                    clearButton
                                                                                    className="subject-picker"
                                                                                    id="teacher-picker"
                                                                                    labelKey={(option) =>
                                                                                        `${option.firstName} ${option.lastName}`
                                                                                    }
                                                                                    options={this.state.teachers}
                                                                                    placeholder={language.oneornone}
                                                                                    onChange={(teacher) => {
                                                                                        if (!teacher.length) {
                                                                                            this.setState({
                                                                                                teacher,
                                                                                                gradeTeach: prin.grade,
                                                                                            });
                                                                                        }
                                                                                        this.setState({
                                                                                            teacher,
                                                                                            gradeTeach:
                                                                                                teacher[0]?.grade || prin.grade,
                                                                                        });
                                                                                    }}
                                                                                    selected={this.state.teacher}
                                                                                />
                                                                            </Form.Group>
                                                                        </Form.Row>
                                                                    </>
                                                                )}
                                                            <Form.Row>
                                                                <Form.Group as={Col} controlId="reason">
                                                                    <Form.Label>
                                                                        {language.reason}{" "}
                                                                        <small className="text-muted">
                                                                            {language.onlyforprin}
                                                                        </small>
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={this.onChange}
                                                                        value={this.state.reason}
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col} controlId="type">
                                                                    <Form.Label>{language.jobfor}</Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={(event) => {
                                                                            this.onChange(event);
                                                                            this.setState({tempTypeNull: false});
                                                                        }}
                                                                        value={this.state.type}
                                                                        style={{
                                                                            borderColor:
                                                                                this.state.tempTypeNull && "#ff6175",
                                                                        }}
                                                                    >
                                                                        <option value="" selected disabled hidden>
                                                                            {language.fi ? "Valitse" : "Välj"}
                                                                        </option>
                                                                        {QualificationList.map((quali, index) => (
                                                                            <option value={quali.val}>
                                                                                {language.fi
                                                                                    ? quali.name_fi
                                                                                    : quali.name_swe}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            {other && (
                                                                <Form.Row>
                                                                    <Form.Group as={Col} controlId="otherTxt">
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder={
                                                                                language === "fi"
                                                                                    ? "Muu, mikä?"
                                                                                    : "Annan, vad?"
                                                                            }
                                                                            onChange={this.onChange}
                                                                            value={this.state.otherTxt}
                                                                        />
                                                                    </Form.Group>
                                                                </Form.Row>
                                                            )}
                                                            <Form.Row>
                                                                <Form.Group as={Col}>
                                                                    <Form.Label>
                                                                        {language.chosensubjects}
                                                                    </Form.Label>
                                                                    <Typeahead
                                                                        className="subject-picker"
                                                                        id="subject-picker"
                                                                        multiple
                                                                        allowNew
                                                                        newSelectionPrefix={
                                                                            language.sv ? "Lägg till: " : "Lisää: "
                                                                        }
                                                                        options={
                                                                            language.sv
                                                                                ? Subjects.sort()
                                                                                : Subjects_fi.sort()
                                                                        }
                                                                        placeholder={language.oneormore}
                                                                        onChange={(subjects) => {
                                                                            this.setState({subjects});
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Row>
                                                                <Form.Group as={Col}>
                                                                    <Form.Label>{language.grade}</Form.Label>
                                                                    <Typeahead
                                                                        className="subject-picker"
                                                                        id="grade-picker"
                                                                        multiple
                                                                        placeholder={language.oneormore_standard}
                                                                        selected={gradeTeach}
                                                                        options={
                                                                            language.fi
                                                                                ? GradeArray.fi
                                                                                : GradeArray.sv
                                                                        }
                                                                        onChange={(gradeTeach) => {
                                                                            this.setState({
                                                                                gradeTeach: gradeTeach.sort(),
                                                                            });
                                                                        }}
                                                                    />
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Form.Group controlId="additionalInfo">
                                                                <Form.Label>{language.extra}</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows={4}
                                                                    placeholder={language.additionalInfoText}
                                                                    onChange={this.onChange}
                                                                    value={this.state.additionalInfo}
                                                                />
                                                            </Form.Group>
                                                            <Form.Row>
                                                                <Form.Group as={Col}>
                                                                    <Form.File
                                                                        ref={this.inputFileRef}
                                                                        className="text-muted"
                                                                        id="files"
                                                                        type="file"
                                                                        onChange={this.onFileChange}
                                                                        style={{display: "none"}}
                                                                        multiple
                                                                    />
                                                                    <Button
                                                                        variant="custom-reverse"
                                                                        block
                                                                        onClick={this.handleFileClick}
                                                                    >
                                                                        {language.choosedocs}
                                                                    </Button>
                                                                    <p
                                                                        className="text-muted mt-2 text-center"
                                                                        style={{fontSize: "1em"}}
                                                                    >
                                                                        {language.docswarning}
                                                                    </p>
                                                                </Form.Group>
                                                            </Form.Row>
                                                            <Row>
                                                                <ul>
                                                                    {this.state.files.length > 0
                                                                        ? this.state.files.map((file, index) => (
                                                                            <li>{file.name}</li>
                                                                        ))
                                                                        : ""}
                                                                </ul>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    <Button
                                                                        variant="custom"
                                                                        block
                                                                        size="lg"
                                                                        type="submit"
                                                                        disabled={this.state.isLoading}
                                                                    >
                                                                        {language.addjob}
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>{button}</Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Col>
                                </div>
                            </>
                        )}
                        <Modal
                            show={open}
                            /* shouldCloseOnOverlayClick={false} */
                            backdrop="static"
                            onHide={this.handleCloseChange}
                            keyboard={false}
                            animation={false}
                            className="sos-modal"
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{language.postsos}</Modal.Title>
                            </Modal.Header>
                            <div className="m-auto pt-2">
                                <h4>{this.state.school}</h4>
                                {prin?.moreSchools?.length > 0 && (
                                    <ChooseSchool
                                        language={language}
                                        onClick={(e) =>
                                            this.setState({school: e.currentTarget.id})
                                        }
                                        options={[prin.school, ...prin?.moreSchools]}
                                        reminder={
                                            !this.state.school &&
                                            this.state.noSchool && (
                                                <span className="check-school m-auto">
                          <p>!</p>
                        </span>
                                            )
                                        }
                                    />
                                )}
                            </div>
                            {!editinfo ? (
                                <>
                                    <Modal.Body>
                                        <Card className="card-block">
                                            <Row>
                                                <Card.Body>
                                                    <Card.Title
                                                        style={{fontWeight: "bold"}}
                                                        className="mb-0"
                                                    ></Card.Title>
                                                    <Card.Text
                                                        style={{
                                                            fontSize: "2em",
                                                            color: "#ff6175",
                                                            fontWeight: "bold",
                                                        }}
                                                        className="mb-0"
                                                    >
                                                        {new Date().toLocaleDateString("fi", fi)}
                                                    </Card.Text>
                                                    <Card.Text
                                                        style={{
                                                            fontSize: "1.6em",
                                                            color: "rgb(46, 47, 49)",
                                                            fontWeight: "bold",
                                                        }}
                                                        className="mb-0"
                                                    >
                                                        {prin?.defaults?.start +
                                                            " - " +
                                                            prin?.defaults?.end}
                                                    </Card.Text>
                                                    <Card.Text
                                                        style={{
                                                            fontSize: "1.6em",
                                                            color: "rgb(46, 47, 49)",
                                                        }}
                                                        className="mb-0"
                                                    >
                                                        {prin?.defaults?.info}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Row>
                                            <Row className="justify-content-sm-center">
                                                <hr className="custom-hr-2"/>
                                            </Row>
                                        </Card>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            disabled={
                                                editinfo ||
                                                (this.state.prin?.moreSchools && !this.state?.school) ||
                                                this.state.isLoading
                                            }
                                            block
                                            variant="custom"
                                            onClick={this.postSos}
                                        >
                                            {language.addjob}
                                        </Button>
                                        <Button block variant="custom" onClick={this.editSos}>
                                            {language.editdropdwn}
                                        </Button>
                                    </Modal.Footer>
                                </>
                            ) : (
                                <>
                                    <Modal.Body>
                                        <Card className="card-block">
                                            <Form noValidate onSubmit={this.saveEdit}>
                                                <Row>
                                                    <Col>
                                                        <Card.Body>
                                                            <Row className="f-col">
                                                                <Col>
                                                                    <h4 style={{display: "flex"}}>
                                                                        {language.sostandardtext}
                                                                    </h4>
                                                                </Col>
                                                            </Row>
                                                            <Row className="f-col">
                                                                <Form.Group controlId="start_sos" as={Col}>
                                                                    <Form.Label className="text-muted lt">
                                                                        {language.begins}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={this.onChange}
                                                                        value={start_sos}
                                                                    >
                                                                        {hours.map((hour, hr_index) =>
                                                                            minutes.map((min, index) => (
                                                                                <option key={index}>
                                                                                    {hour + ":" + min}
                                                                                </option>
                                                                            ))
                                                                        )}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                                <Form.Group controlId="end_sos" as={Col}>
                                                                    <Form.Label className="text-muted lt">
                                                                        {language.ends}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="select"
                                                                        onChange={this.onChange}
                                                                        value={end_sos}
                                                                    >
                                                                        {hours.map((hour, hr_index) =>
                                                                            minutes.map((min, index) => (
                                                                                <option
                                                                                    disabled={
                                                                                        prin.defaults.start >=
                                                                                        hour + ":" + min
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    key={index}
                                                                                >
                                                                                    {hour + ":" + min}
                                                                                </option>
                                                                            ))
                                                                        )}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Row>
                                                            <Row className="f-col">
                                                                <Form.Group controlId="info_sos" as={Col}>
                                                                    <Form.Label className="text-muted lt">
                                                                        {language.freetext}
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        as="textarea"
                                                                        rows={4}
                                                                        onChange={this.onChange}
                                                                        value={info_sos}
                                                                    />
                                                                </Form.Group>
                                                            </Row>
                                                            <Button variant="custom" size="lg" type="submit">
                                                                {language.savechanges}
                                                            </Button>
                                                        </Card.Body>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Card>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button
                                            block
                                            disabled={
                                                editinfo ||
                                                (this.state.prin?.moreSchools && !this.state?.school) ||
                                                this.state.isLoading
                                            }
                                            variant="custom"
                                            onClick={this.postSos}
                                        >
                                            {language.addjob}
                                        </Button>
                                        <Button
                                            block
                                            disabled={editinfo}
                                            variant="custom"
                                            onClick={this.editSos}
                                        >
                                            {language.editdropdwn}
                                        </Button>
                                    </Modal.Footer>
                                </>
                            )}
                        </Modal>
                    </Container>
                </div>
            </div>
        </>);
    }
}

AddTempPos.propTypes = {
    addPosition: PropTypes.func.isRequired,
    logoutTeacher: PropTypes.func.isRequired,
    saveSchoolSettings: PropTypes.func.isRequired,
    sosPos: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    addPosition,
    sosPos,
    logoutTeacher,
    saveSchoolSettings,
})(withRouter(AddTempPos));
